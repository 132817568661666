(function (angular, moment, _, $, saveAs, policyInfoFunctions) {
    'use strict';

    angular.module('MyHippoProducer.Controllers').controller('PolicyListController', PolicyListController);
    PolicyListController.$inject = ['$log', '$scope', '$state', '$stateParams', 'toaster', 'spinnerService', 'WorkflowUtil', 'APIService', 'TimeRangeFilterService', '$q', 'UserService', 'HeapService', 'FlagshipService', '$window', '$location'];
    function PolicyListController ($log, $scope, $state, $stateParams, toaster, spinnerService, WorkflowUtil, APIService, TimeRangeFilterService, $q, UserService, HeapService, FlagshipService, $window, $location) {
        $log.info('PolicyListController');
        $scope.currentPage = 1;
        $scope.itemsPerPage = 100;
        $scope.totalItems = 0;
        $scope.policies = [];
        $scope.canViewAgentName = UserService.canViewAgentName();
        $scope.isLoading = false;
        $scope.hasFetchedResults = false;
        $scope.shouldUsePPV2Version = false;
        $scope.shouldRedirectToReactPolicyView = false;

        $scope.defaultFilter = {
            scope: 'organization',
            organization: 'all',
            status: undefined,
            effectiveDateAfter: undefined,
            effectiveDateBefore: undefined,
            timeRange: TimeRangeFilterService.getTimeRange(),
            keyword: undefined,
            sortField: 'updatedAt',
            sortOrder: -1,
            sortReverse: true,
        };

        $scope.currentPolicy = undefined;

        // Get PPV2 Flagship config
        FlagshipService.getCampaignData({campaignID: window.appConfig.FLAGSHIP_PPV2_CAMPAIGN_ID}).then((res) => {
            if (res.data &&
                res.data.variation &&
                res.data.variation.modifications &&
                res.data.variation.modifications.value) {
                $scope.shouldUsePPV2Version = res.data.variation.modifications.value.enablePoliciesPage;
                $scope.shouldRedirectToReactPolicyView = res.data.variation.modifications.value.enableReactPolicyPage;
            }
        });
        // Redirect to PPV2 Policies page if supported
        $scope.$watch('shouldUsePPV2Version', function (shouldUseReactVersion) {
            if(shouldUseReactVersion) {
                const protocol = $location.protocol();
                const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
                const subdomain = $window.location.host.split('.')[0];
                const producersReactDomain = `${protocol}://${subdomain}.${reactProducerDomain}`;
                $window.location.href = `${producersReactDomain}/policies`;
            }
        });

        const setFilters = () => {
            const stateParamsAreEmpty = Object.values($stateParams).every((value) => _.isEmpty(value));
            const cleanedStateParams = _.omit($stateParams, ['#']);
            return stateParamsAreEmpty ? $scope.filters = Object.assign({}, cleanedStateParams, $scope.defaultFilter) : Object.assign({}, $scope.defaultFilter, cleanedStateParams);
        };

        $scope.filters = setFilters();

        $scope.$watch('policies', function (newPolicies) {
            $scope.hasCommunities = newPolicies.some(p => !!p.community_name);
        });

        $scope.localeSegmentationComparator = function(v1, v2) {
            // If we don't get strings, just compare by index
            if (v1.type !== 'string' || v2.type !== 'string') {
                return (v1.index < v2.index) ? -1 : 1;
            }

            // In the dictionary, A comes before A+; therefore we override the Comparator while adding a special condition here;
            // Compare segmentation, while preferring A+ over the other scores
            if(v1.value === 'A+' && v2.value !== 'A+'){
                return 1;
            }
            if(v2.value === 'A+' && v1.value !== 'A+'){
                return -1;
            }

            // Compare strings alphabetically, taking locale into account
            return v2.value.localeCompare(v1.value);
        };

        $scope.sortPolicies = function (sortField) {
            if ($scope.filters.sortField !== sortField) {
                $scope.filters.sortField = sortField;
            }
            $scope.filters.sortOrder *= -1;
            $scope.filters.sortReverse = !$scope.filters.sortReverse;

            // Override the Comparator if the selected sorting field is 'segmentation'
            $scope.filters.sortComparator = $scope.filters.sortField === 'segmentation' ? $scope.localeSegmentationComparator : $scope.filters.sortOrder;
        };

        $scope.openPolicyDetails = function (policy) {
            if ($scope.shouldRedirectToReactPolicyView) {
                const protocol = $location.protocol();
                const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
                const subdomain = $window.location.host.split('.')[0];
                const producersReactDomain = `${protocol}://${subdomain}.${reactProducerDomain}`;
                $window.location.href = `${producersReactDomain}/policy?policyId=${policy.id}`;
                return;
            }
            const policyDetailsPage = UserService.showNewPolicyDesign() ? 'portal.policyDetailsV2' : 'portal.policyDetails';
            if (UserService.showNewPolicyDesign()) {
                HeapService.trackEvent('open_policy', {
                    'producer_id': policy.producer_id,
                    'policy_number': policy.policy_number,
                    'policy_address': policy.address,
                });
            }
            $state.go(policyDetailsPage, {id: policy.id});
        };

        let downloadReportInProgress = false;
        $scope.downloadPolicyReport = function () {
            spinnerService.show('globalSpinner');
            if (downloadReportInProgress) return;
            downloadReportInProgress = true;
            APIService.downloadPolicyReport($scope.filters).then(csv => {
                saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `policyreport_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
                toaster.pop('success', 'Policy Report', 'Successfully downloaded.');
            }).catch(() => {
                toaster.pop('error', 'Policy Report', 'An error occurred while generating the report. Please contact support.');
            }).finally(() => {
                downloadReportInProgress = false;
                spinnerService.hide('globalSpinner');
            });
        };
        $scope.hoverInRatingStarts = (policy) => {
            $scope.currentPolicy = {
                id: policy.id,
                revisitRatingTooltip: policy.revisitRatingTooltip,
            };
        };

        $scope.shouldUseSegmentation = false;
        $scope.$on('segmentation.turnOn', function () {
            $scope.shouldUseSegmentation = true;
        });
    }

    angular.module('MyHippoProducer.Controllers').controller('PendingPoliciesController', PendingPoliciesController);
    PendingPoliciesController.$inject = ['$log', '$scope', '$state', '$stateParams', '$mdDialog', 'toaster', 'spinnerService', 'WorkflowUtil', 'APIService', 'HeapService', 'WorkflowActions', 'FlagshipService', '$window', '$location'];
    function PendingPoliciesController ($log, $scope, $state, $stateParams, $mdDialog, toaster, spinnerService, WorkflowUtil, APIService, HeapService, WorkflowActions, FlagshipService, $window, $location) {
        $log.info('PendingPoliciesController');
        const {
            FLAGSHIP_SEGMENTATION_CAMPAIGN_ID
        } = window.appConfig;
        $scope.currentPage = 1;
        $scope.itemsPerPage = 100;
        $scope.filters = {
            sortField: $stateParams.sortField || 'effective_date',
            sortOrder: $stateParams.sortOrder || -1
        };
        $scope.currentPolicy = undefined;
        $scope.shouldRedirectToReactPolicyView = false;

        // Get PPV2 Flagship config
        FlagshipService.getCampaignData({campaignID: window.appConfig.FLAGSHIP_PPV2_CAMPAIGN_ID}).then((res) => {
            if (res.data &&
                res.data.variation &&
                res.data.variation.modifications &&
                res.data.variation.modifications.value) {
                $scope.shouldRedirectToReactPolicyView = res.data.variation.modifications.value.enableReactPolicyPage;
            }
        });

        const calculateReasonsColorAndText = function (policy) {
            if (!policy.reasons[0]) {
                return policy;
            }
            if (moment(policy.reasons[0].cancellationDate, 'L').isBefore(new Date(), 'day')) {
                policy.reasons[0].text = 'past due';
                policy.reasons[0].color = 'red-font';
            } else if (moment(policy.reasons[0].cancellationDate, 'L').isSame(new Date(), 'day')) {
                policy.reasons[0].text = 'due today';
                policy.reasons[0].color = 'blue-font';
            } else {
                policy.reasons[0].text = '';
                policy.reasons[0].color = '';
            }
            return policy;
        };

        $scope.findPendingPolicies = function () {
            spinnerService.show('globalSpinner');
            $scope.filters.offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
            $scope.filters.limit = parseInt($scope.itemsPerPage, 10);

            APIService.findPendingPolicies($scope.filters).then(function (results) {
                $scope.policies = results.policies.map(calculateReasonsColorAndText);
                $scope.totalItems = results.count;
                HeapService.trackEvent('total_pending_policies', {
                    total_pending_policies: results.count,
                });
            }).finally(() => spinnerService.hide('globalSpinner'));
        };

        $scope.$watch('[currentPage, itemsPerPage]', $scope.findPendingPolicies, true);

        $scope.filterPendingPolicies = function () {
            $scope.currentPage = 1;
            $scope.findPendingPolicies();
        };

        $scope.sortPendingPolicies = function (sortField) {
            $scope.currentPage = 1;
            if ($scope.filters.sortField !== sortField) $scope.filters.sortOrder = 1;
            $scope.filters.sortField = sortField;
            $scope.filters.sortOrder *= -1;
            $state.go('.', { sortField: sortField, sortOrder: $scope.filters.sortOrder }, { location: 'replace' })
                .then(() => $scope.findPendingPolicies());
        };

        $scope.openPolicyDetails = function (policy) {
            if ($scope.shouldRedirectToReactPolicyView) {
                const protocol = $location.protocol();
                const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
                const subdomain = $window.location.host.split('.')[0];
                const producersReactDomain = `${protocol}://${subdomain}.${reactProducerDomain}`;
                $window.location.href = `${producersReactDomain}/policy?policyId=${policy.id}`;
                return;
            }

            HeapService.trackEvent('open_pending_policy', {
                'producer_id': policy.producer_id,
                'policy_number': policy.policy_number,
                'policy_address': `${policy.street}, ${policy.city}, ${policy.state.toUpperCase()} ${policy.zip}`,
            });
            $state.go('portal.policyDetailsV2', {id: policy.id});
        };

        $scope.copyEmail = function($event, policy) {
            $event.stopPropagation();
            const queryNode = document.querySelector(`.copy-policy-email-${policy.id}`);
            const range = document.createRange();
            range.selectNode(queryNode);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            toaster.pop('success', 'Email copied');
            HeapService.trackEvent('pending_copy_email', {
                policy_number: policy.policy_number,
                policy_email: policy.email,
            });
        };

        const identifyPendingReason = function(policy, reason) {
            return policy.reasons.find((r) => r.reason === reason) || policy.cancellation_reasons.find((r) => r === reason);
        };

        $scope.hasActionModal = function (policy) {
            return identifyPendingReason(policy, 'Terms and Conditions Acceptance Needed') || identifyPendingReason(policy, 'Smart home kit not activated');
        };

        $scope.openActionModal = function($event, policy) {
            $event.stopPropagation();
            if (identifyPendingReason(policy, 'Terms and Conditions Acceptance Needed')) {
                policy.termsAcceptanceModalOpen = !policy.termsAcceptanceModalOpen;
            } else if (identifyPendingReason(policy, 'Smart home kit not activated')) {
                policy.smartHomeActionModalOpen = !policy.smartHomeActionModalOpen;
            }
            APIService.findPolicyById(policy.id).then(data => {
                $scope.termsAcceptanceLink = data.policyInfo.checkout_data.customer_approval_link;
                $scope.policyData = data.policyInfo;
            });
        };

        const copySecretToClipboard = function(secret) {
            const $body = document.getElementsByTagName('body')[0];
            const $tempInput = document.createElement('INPUT');
            $body.appendChild($tempInput);
            $tempInput.setAttribute('value', secret);
            $tempInput.select();
            document.execCommand('copy');
            $body.removeChild($tempInput);
        };

        $scope.copyTermsLink = function($event, policy) {
            $event.stopPropagation();
            if ($scope.termsAcceptanceLink) {
                copySecretToClipboard($scope.termsAcceptanceLink);
                toaster.pop('success', 'Link copied');
                policy.termsAcceptanceModalOpen = false;
                HeapService.trackEvent('pending_copy_terms_link', {
                    policy_number: policy.policy_number,
                });
            } else {
                toaster.pop('error', 'can not find terms acceptance link');
            }
        };

        const calculateTime = function (lastCustomerApprovalSendTime) {
            const now = new Date();
            let calculatedTime = 0;
            for (const unit of ['days', 'hours', 'minutes']) {
                calculatedTime = moment(now).diff(lastCustomerApprovalSendTime, unit);
                if (calculatedTime !== 0) {
                    return { unit: unit, time: calculatedTime };
                }
            }
            if (calculatedTime === 0) {
                return {
                    unit: 'seconds',
                    time: moment(now).diff(lastCustomerApprovalSendTime, 'seconds')
                };
            }
        };

        $scope.openEmailTermsModal = function($event, policy) {
            $event.stopPropagation();
            const lastCustomerApprovalSendTime = _.get($scope.policyData, 'checkout_data.last_customer_approval_send_date');
            const daysAfterLastCustomerApprovalSend = moment(new Date()).diff(lastCustomerApprovalSendTime, 'days');
            const timeAfterLastCustomerApprovalSend = calculateTime(lastCustomerApprovalSendTime);
            const policyData = $scope.policyData;
            if (lastCustomerApprovalSendTime && daysAfterLastCustomerApprovalSend <= 7) {
                $mdDialog.show({
                    template: `
                        <basic-modal title=title primary-text="'Send Email'" secondary-text="'Cancel'" can-close='true' primary-action=primaryAction()>
                            <p class="resend-customer-email-body">We send <b>{{firstName}} {{lastName}}</b> the terms and conditions acceptance email on <span class="resend-customer-email-date">{{lastCustomerApprovalSendTime}}</span> to <b>{{email}}</b></p>
                            <p class="resend-customer-email-body">Are you sure you want to resend the email?</p>
                        </basic-modal>
                    `,
                    controller: function($scope) {
                        $scope.title = `Email sent ${timeAfterLastCustomerApprovalSend.time} ${timeAfterLastCustomerApprovalSend.unit} ago`;
                        $scope.lastCustomerApprovalSendTime = moment(lastCustomerApprovalSendTime).format('ll');
                        $scope.email = policyData.personal_information.email;
                        $scope.firstName = policyData.personal_information.first_name;
                        $scope.lastName = policyData.personal_information.last_name;
                        $scope.primaryAction = function() {
                            WorkflowActions.resendCustomerAgreementEmail(policyData);
                            $mdDialog.hide();
                        };
                    }
                });
            } else {
                WorkflowActions.resendCustomerAgreementEmail($scope.policyData);
            }
            policy.termsAcceptanceModalOpen = false;
            HeapService.trackEvent('pending_open_email_terms', {
                policy_number: policy.policy_number,
            });
        };

        $scope.copySmartHomeInfo = function($event, policy) {
            $event.stopPropagation();
            copySecretToClipboard(`${location.host}/#/policies2/${policy.id}/view/discounts#smart-home-kit`);
            toaster.pop('success', 'Link copied');
            policy.smartHomeActionModalOpen = false;
            HeapService.trackEvent('pending_copy_smart_home_info', {
                policy_number: policy.policy_number,
            });
        };

        $scope.hoverInRatingStarts = (policy) => {
            $scope.currentPolicy = {
                id: policy.id,
                revisitRatingTooltip: policy.revisitRatingTooltip,
            };
        };

        $scope.shouldUseSegmentation = false;

        FlagshipService.getCampaignData({ campaignID: FLAGSHIP_SEGMENTATION_CAMPAIGN_ID }).then((res) => {
            $scope.shouldUseSegmentation =
                res.data &&
                res.data.variation &&
                res.data.variation.modifications &&
                res.data.variation.modifications.value &&
                res.data.variation.modifications.value.accessCustomerSegmentation;
        });
    }

    angular.module('MyHippoProducer.Controllers').controller('PolicyDetailsController', PolicyDetailsController);

    function PolicyDetailsController (spinnerService, $log, $scope, $q, $state, $stateParams, $timeout, $document, WorkflowUtil, toaster, policyData, APIService) {

        // term selection
        const formatTerms = (term, index) => Object.assign({}, term, {display: `Term ${index}`});
        const previousTerms = policyData.policyInfo.previous_terms || [];
        const nextTerms = policyData.policyInfo.next_terms || [];
        const currentTerm = [{ policy_id: policyData.policyInfo.id }];

        $scope.allTerms = previousTerms.concat(currentTerm, nextTerms).map(formatTerms).reverse();
        $scope.termRange = `B: ${policyData.policyInfo.effective_date} E: ${policyData.policyInfo.expiration_date}`;
        $scope.termSelection = policyData.policyInfo.id;
        $scope.changePolicyTerm = function () {
            if (policyData.policyInfo.id !== this.termSelection) {
                $state.go('portal.policyDetails', {id: this.termSelection});
            }
        };

        $scope.doPageSave = function (context, deltaUpdates) {
            spinnerService.show('globalSpinner');
            $log.info('save updates: ', deltaUpdates);
            // TODO: make sure right service.
            // TODO: make function
            return APIService.updateProducerPolicy(
                $scope.policyId,
                context,
                deltaUpdates
            ).then(function (policyData) {
                $scope.updatePolicyData(policyData); // update policy data
                $scope.workflowErrors = []; // reset server errors

                $scope.$broadcast('policyupdate.success');
                toaster.pop('success', 'Policy', 'Successfully saved!');
                return policyData;
            }, function (errors) {
                $scope.workflowErrors = errors; // set server errors
                $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on workflowModel

                $scope.$broadcast('policyupdate.error');
                toaster.pop('error', 'Policy', 'An error occurred while saving the policy<br>' + (typeof (errors) === 'string' ? errors : ''), 3000, 'trustedHtml');
                throw errors;
            }).finally(() => spinnerService.hide('globalSpinner'));
            $log.info('doing page save', details);
        };

        // Modify personal information section
        const personalInformationPage = _.find(policyData.workflow.pages, page => page.properties.key === 'personal_information_page');
        const additionalInfoSection = _.find(personalInformationPage.sections, section => section.properties.key === 'additional_personal_information_section');
        let phoneNumberField = _.find(additionalInfoSection.fields, field => field.key === 'personal_information.phone_number');
        let secondaryPhoneNumberField = _.find(additionalInfoSection.fields, field => field.key === 'personal_information.phone_number_2');
        let emailField = _.find(additionalInfoSection.fields, field => field.key === 'personal_information.email');
        _.merge(Object.assign(emailField, {
            format: 'template',
            template: 'mh-policy-detail-update-email'
        }));
        _.merge(Object.assign(phoneNumberField, {
            format: 'template',
            template: 'mh-policy-detail-update-phone'
        }));
        _.merge(Object.assign(secondaryPhoneNumberField, {
            format: 'template',
            template: 'mh-policy-detail-update-secondary-phone'
        }));

        // Add field to change mortgages in checkout section
        const checkoutPage = _.find(policyData.workflow.pages, page => page.properties.key === 'checkout_page');
        const escrowInformationSectionIndex = checkoutPage.sections.findIndex(s => s.properties.key === 'escrow_section');
        checkoutPage.sections.splice(escrowInformationSectionIndex + 1, 0, {
            properties: {
                type: '',
                key: 'update_mortgage_lenders',
                title: 'Update Mortgage Lenders',
                visible: 'F:!policy.property_data.no_mortgage_discount'
            },
            fields: [
                {
                    label: `Add / edit lender information`,
                    key: 'edit-mortgage-list',
                    format: 'template',
                    template: 'mh-mortgages-list',
                }
            ]
        });

        // inject premium info page into the workflow
        policyData.workflow.pages.unshift({
            properties: {title: 'Premium Information', key: 'premium_information_page', required: false},
            sections: [{
                properties: {key: 'premium_information_section', readonly: true},
                fields: [
                    {label: 'Base', key: 'term_quote.base', type: 'number', format: 'dollar'},
                    {label: 'Fees', key: 'term_quote.total_fees', type: 'number', format: 'dollar'},
                    {label: 'Optionals', key: 'term_quote.total_optionals', type: 'number', format: 'dollar'},
                    {label: 'Totals', key: 'term_quote.total', type: 'number', format: 'dollar'}
                ]
            }]
        });

        // inject policy info page into the workflow
        policyData.workflow.pages.unshift({
            properties: {title: 'Policy Information', key: 'policy_information_page', required: false, readonly: true},
            sections: [{
                properties: {key: 'policy_information_section'},
                fields: [
                    {label: 'Address', key: 'address_string', value: "F:policy.property_data.address.street + ' ' + (policy.property_data.address.street2 || '') + ', ' + policy.property_data.address.city + ', ' + policy.property_data.address.state.toUpperCase() + ' ' + policy.property_data.address.zip"},
                    {label: 'Policy Number', key: 'policy_number', type: 'string'},
                    {label: 'Client', key: 'personal_information.full_name', type: 'string'},
                    {label: 'Policy Type', key: 'product', type: 'string'},
                    {
                        label: 'Effective Date',
                        key: 'effective_date',
                        type: 'string',
                        readonly: false,

                        // specific to fronted only
                        format: 'producer_effective_date',
                        on_save: (value) => {
                            return APIService.updatePolicyEffectiveDate(policyData.policyInfo.id, value).then((result) => {
                                const $checkExisting = document.querySelector('div.premium-change-message');
                                let oldPrice = policyData.policyInfo.term_quote.total;
                                Object.assign(policyData.policyInfo, result);
                                if (result.term_quote.total !== oldPrice) {
                                    oldPrice = Number(oldPrice).toLocaleString();
                                    const newPrice = `$${Number(result.term_quote.total).toLocaleString()}`;
                                    const $priceInput = document.querySelector('[ng-model="model.term_quote.total"]');
                                    $priceInput.value = newPrice;
                                    const $message = $checkExisting || document.createElement('div');
                                    $message.className = 'premium-change-message';
                                    $message.innerHTML = `
                                        <span class="ion-information-circled"></span>
                                        Please note - price changed from <b>$${oldPrice}</b> due to effective date update
                                    `;
                                    if (!$checkExisting) {
                                        $priceInput.parentNode.appendChild($message);
                                    }
                                } else if ($checkExisting) {
                                    $checkExisting.parentNode.removeChild($checkExisting);
                                }
                            });
                        }
                    },
                    {
                        label: 'Effective Time',
                        key: 'effective_time',
                        type: 'string',
                        readonly: true
                    },
                    {label: 'Status', key: 'status', type: 'string'},
                    {label: 'Annual Premium', key: 'term_quote.total', type: 'number', format: 'dollar'},
                    { label: 'Zillow', key: 'property_data.zillow.url', type: 'string', format: 'url', display: `F:property_data.zillow.url ? ('See more details for ' + property_data.address.street + ' on Zillow') : 'Zillow page is not available'` },
                    {label: 'Organization Name', key: 'policy_org_name', type: 'string', format: 'template', template: 'mh-producer-detail-sub-org-list' },
                    {label: 'Agent', key: 'producer_name', type: 'string'}
                ]
            }]
        });
        _.set(policyData.policyInfo, 'personal_information.full_name',
            _.get(policyData.policyInfo, 'personal_information.first_name') + ' ' + _.get(policyData.policyInfo, 'personal_information.last_name')
        );

        $scope.policyId = policyData.policyInfo.id;
        $scope.isViewMode = true;
        $scope.workflow = policyData.workflow;
        $scope.phases = {};
        $scope.workflowModel = WorkflowUtil.getWorkflowObjectInfo(policyData.policyInfo);

        $scope.updatePolicyData = function (policyData) {
            policyData.phases && ($scope.phases = policyData.phases);
            policyData.referrals && ($scope.referrals = policyData.referrals);
            policyData.followups && ($scope.followups = policyData.followups);
            policyData.underwriting && ($scope.underwriting = policyData.underwriting);

            $scope.policyInfo = _.cloneDeep(policyData.policyInfo);

            // required for worflow controllers
            $scope.workflowOriginalModel = $scope.policyInfo;
            $scope.workflowModel = WorkflowUtil.getWorkflowObjectInfo(policyData.policyInfo);
            $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on workflowModel
            WorkflowUtil.extendWithFunctions($scope.workflowModel, policyInfoFunctions.get(moment));

            // update page visibility status
            policyData.workflow.pages.forEach(page => {
                $log.info(page.properties.key, page.properties.visible, WorkflowUtil.evaluateExpression(page.properties.visible, $scope.workflowModel));
                page.properties.client_visible = true;
                if (page.properties.hasOwnProperty('visible')) {
                    page.properties.client_visible = WorkflowUtil.evaluateExpression(page.properties.visible, $scope.workflowModel);
                }
            });
        };
        $scope.updatePolicyData(policyData);

        $scope.changePage = function ($event, pageKey) {
            if ($state.params.pageKey === pageKey) {
                return $state.go('portal.policyDetails', {pageKey: '-'});
            }

            $state.go('portal.policyDetails', {pageKey: pageKey});
            $timeout(function () {
                $document.scrollToElementAnimated($('#' + $event.target.id), 100);
            }, 400);
        };
    }

    angular.module('MyHippoProducer.Controllers').controller('PolicyCreateController', PolicyCreateController);
    PolicyCreateController.$inject = ['$log', '$scope', '$state', '$sce', 'clientAppUrl', 'UserService'];
    function PolicyCreateController ($log, $scope, $state, $sce, clientAppUrl, UserService) {
        if (UserService.isPolicyCreationDisabled()) {
            $log.info('Not allowed to create policies');
            $state.go('portal.policiesList');
        } else {
            $log.info('PolicyCreateController');
            $scope.clientAppUrl = $sce.trustAsResourceUrl(clientAppUrl);
        }
    }
})(window.angular, window.moment, window._, window.$, window.saveAs, window.policyInfoFunctions);
